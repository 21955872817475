<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 py-4 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  :src="require('@/assets/imgs/pages/infrastructure5.png')"
                  alt="Icona infrastruttura IT"
                  class="service_image_position"
                  contain
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">
                  L'infrastruttura informatica è il cuore pulsante della tua
                  azienda.
                </h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/infrastructure5.png')"
                    alt="Icona infrastruttura IT"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">
                  Rendila sempre efficiente e performante!
                </h2>

                <p>
                  L'infrastruttura IT è l'insieme di tutti i componenti, sia
                  hardware che software, necessari a fornire servizi e rendere
                  efficiente, produttiva e competitiva la tua azienda.
                </p>

                <p>
                  Ci occupiamo di rendere la tua infrastruttura, robusta,
                  stabile e scalabile, consigliandoti le migliori soluzioni per
                  il tuo business. <br />
                </p>
                <p>
                  L'analisi delle condizioni operative permette di analizzare il
                  carico di lavoro dei vari componenti per evidenziare eventuali
                  criticità e ottimizzare di conseguenza gli investimenti.
                </p>
                <p>
                  Il monitoraggio attivo consente di anticipare i guasti per
                  evitare che la tua infrastuttura subisca blocchi improvvisi e
                  inaspettati costringendoti a fermarti.
                </p>

                <!-- Servizi offerti -->
                <div class="mt-12 mb-12">
                  <h3 class="mb-2">
                    <strong> Alcuni servizi offerti: </strong>
                  </h3>
                  <ul>
                    <li>Amministrazione server, utenti e posti di lavoro</li>
                    <li>Amministrazione servizi di rete (AD | DHCP | DNS | etc.)</li>
                    <li>Gestione di hypervisor in ambienti virtualizzati</li>
                    <li>
                      Configurazione dispoostivi connessi alla rete come PC,
                      Stampanti, IpCameras, linee di produzione, etc.
                    </li>
                    <li>
                      Configurazione di switch, definizione di VLAN per
                      segmentazione traffico di rete
                    </li>
                    <li>
                      Interconnessione, su reti dedicate, di macchine abilitate
                      per Industria 4.0 all'infrastruttura aziendale
                    </li>
                    <li>Gestione reti wireless</li>
                    <li>
                      Soluzioni per il monitoraggio attivo dell'infrastruttura
                      informatica
                    </li>
                  </ul>
                </div>
              </v-col>

              <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="support_it"
                  title="Hai bisogno di supporto informatico per gestire o amministrare al meglio l'infrastruttura della tua azienda?"
                />
              </v-col>

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Amministrazione infrastrutture IT",
    meta: [
      {
        name: "description",
        content:
          "Amministrazione, consulenza e monitoraggio delle infrastrutture IT",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>